
.homepage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url("/public/wave-haikei.png"); 
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
}


.homepage h1 {
  color: white;
  font-size: 3rem;
  margin-bottom: 20px;
}

.homepage p {
  color: white;
  font-size: 1.5rem;
  max-width: 600px; 
  margin: 0 20px;
  line-height: 1.6;
}

.homepage-content {
  background-color: rgba(0, 0, 0, 0.5); 
  padding: 20px;
  border-radius: 10px;
}
