/* body, html {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.dashboardContent {
  background: #2a2a2a;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  box-sizing: border-box;
  height: 100vh;
  margin-top: 80px;
}

.dashboardContent.full-width {
  margin-left: 0; 
  margin-top: 0;
} */


/* App.css */
.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-left: 260px;
  margin-top: 70px;
}

/* .login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  width: 100vw; 
} */
