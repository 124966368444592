.notification {
    position: fixed;
    bottom: 80px;
    right: 80px;
    background-color: #4caf50;
    color: white; 
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: opacity 0.5s ease-in-out;
    z-index: 1000; 
}

.close-btn {
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
    margin-left: 10px;
    font-size: 1.2rem;
}
