.header {
  background-color: #202020;
  position: fixed; 
  width: 100%; 
  top: 0; 
  z-index: 10;
  margin: 0;
  display: flex;
  justify-content: space-between; /* Align profile icon to the right */
  align-items: center;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
}

.header-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  margin-left: 20px;
  padding: 20px;
}

.header h1 {
  font-size: 1.6rem;
  font-weight: 600;
  color: #fcfdff;
  margin: 0;
}

.logo {
  margin-right: 10px; 
  color: #c5254d;
}

.xr {
  display: inline-block; 
  font-size: 0.5em; 
  transform: translateY(-10px);
}

.profile-section {
  position: relative;
  margin-right: 20px;
}

.profile-icon {
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
  width: 40px;
  height: 40px;
  /* border: 0.5px solid #c5254d;  */
}

.profile-avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.dropdown-menu {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #3f3f3f;
  color: #fcfdff;
  width: 280px;
  padding: 10px 0; 
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.user-info {
  padding: 10px 10px 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 5px;
}

.user-name {
  font-size: 1rem;
  color: #fcfdff; 
  font-style: bold;
  margin-bottom: 4px;
  margin-left: 10px;
}

.user-email {
  font-size: 0.9rem;
  color: #b0b0b0;
  font-style: bold;
  margin-left: 10px;
}

.dropdown-item {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #fcfdff;
  padding: 12px;
  padding-left: 20px;
  font-style: bold;
  text-decoration: none;
  transition: background-color 0.3s;
}

.dropdown-item:hover {
  background-color: #0066ff;
}

.logout {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: #fcfdff;
  font-style: bold;
  text-align: left;
  width: 100%;
  cursor: pointer;
  padding: 10px;
  padding-left: 20px;
  color: #f72d2d;
}

.logout:hover {
  background-color: #272727;
}

.dropdown-item .icon {
  margin-right: 10px; 
  color: #bebebe;
}
