.file-options-menu {
    font-family: 'Roboto', sans-serif;
    position: relative;
    display: inline-block;
}

.three-dots-icon {
    display: inline-block; 
    width: 40px; 
    height: 40px; 
    border-radius: 50%;
    background-color: transparent; 
    transition: background-color 0.3s ease; 
    padding: 10px;
    box-sizing: border-box;
}

.menu-dropdown {
    position: absolute;
    top: 100%; 
    right: 0;
    background-color: #3f3f3f;
    color: #fcfdff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 100;
    border-radius: 8px;
    width: 160px;
    padding: 8px 0;
}

.menu-item {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: background-color 0.2s ease;
}

.menu-icon {
    color: #bebebe;
    margin-left: 5px;
    font-size: 18px;
}

.menu-item span {
    margin-top: 5px;
    margin-left: 5px;
    font-size: 14px;
}

.menu-item:hover {
    background-color: #0066ff;
}

.three-dots-icon:hover {
    background-color: lightgray;
}

.delete-option {
    color: #ff4444;
}

.delete-option:hover {
    background-color: #ff4444;
    color: white;
}

/* .menu-item:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.menu-item:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
} */