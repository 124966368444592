body, html {
overflow: hidden;
height: 100%;
width: 100%;
background-color: #121118;
}

.component {
display: flex;
flex-direction: column;
width: 90%;
max-width: 400px;
margin: auto;
padding: 30px;
border: 1px solid #ccc;
border-radius: 20px;
/* Changed from margin-top to transform for better vertical centering */
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-image: url("/public/blob-scene-haikei.png");
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
background-color: #2a2a2a;
box-sizing: border-box;
}

form {
width: 100%;
}

.inputBox {
width: 100%;
padding: 10px;
margin: 10px 0;
border: 1px solid #ccc;
border-radius: 5px;
box-sizing: border-box;
}

.appButton {
width: 100%;
padding: 10px;
background-color: #007bff;
color: white;
border: none;
border-radius: 5px;
cursor: pointer;
box-sizing: border-box;
}

.appButton:hover {
background-color: #0056b3;
}

.signup-text {
width: 100%;
color: #fcfdff;
text-align: center;
margin-top: 10px;
}

.signup-link {
color: #007bff;
cursor: pointer;
}

.signup-link:hover {
text-decoration: underline;
}

/* Media Queries for both horizontal and vertical responsiveness */
@media screen and (max-width: 767px) {
.component {
  width: 90%;
  padding: 20px;
  max-height: 90vh; /* Prevent component from being too tall on small screens */
}
}

@media screen and (max-height: 600px) {
.component {
  padding: 15px;
}

.inputBox {
  margin: 5px 0;
  padding: 8px;
}

.appButton {
  padding: 8px;
}

.signup-text {
  margin-top: 5px;
}
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
.component {
  width: 70%;
}
}

@media screen and (min-width: 1200px) {
.component {
  width: 50%;
}
}
