body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100%;
    font-family: Arial, sans-serif;
}

.profile-container {
    background-color: #181717;
    height: 100vh;
    color: white;
    padding: 20px;
    padding-right: 55px;
    font-family: Arial, sans-serif;

}

.h1 {
    display: flex;
    margin-left: 40px;
    font-size: 1.6rem;
}

.h2 {
    display: flex;
    margin-left: 40px;
    padding: 10px;
    font-size: 18px;  
    font-weight: 600px;
    margin-top: 45px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.profile-user-name {
    background-color:rgba(255, 255, 255, 0.1);
    color: #fcfdff;
    height: auto;
    width: auto;
    padding: 15px 0; 
    margin-top: 40px;
    margin-left: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.your-name {
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.52px;
    margin-left: 30px;
    font-weight: 600;
    margin-bottom: 5px;
    color: rgba(255, 255, 255, 0.7);
}

.name-input {
    width: 400px;
    border-radius: 8px;
    border-width: 2px;
    border-color: transparent;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 6px 16px;
    font-size: 16px;
    line-height: 150%;
    font-weight: 500;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
    color: #fff;
    margin-left: 30px;
    margin-bottom: 15px;
}

.name-input:hover {
    background-color: #888;   
}


.profile-user-email {
    background-color:rgba(255, 255, 255, 0.1);
    color: #fcfdff;
    height: 100px;
    width: auto;
    padding: 10px 0; 
    margin-top: 20px;
    margin-left: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.email {
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.52px;
    margin-left: 30px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.7);
}

.your-email {
    font-size: 15px;
    line-height: 21px;
    letter-spacing: 0.52px;
    margin-left: 30px;
    letter-spacing: 0.62px;
    font-weight: 600;
    color: #fff;
}


.save-button {
    display: block;
    margin: 35px 45px;
    padding: 10px 20px;
    /* font-size: 16px; */
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.save-button:hover {
    background-color: #0056b3;
}

.update-message {
    text-align: center;
    color: #28a745;
    font-size: 14px;
    margin-top: 10px;
}

