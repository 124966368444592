 body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
}


.dashboardNav {
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  width: 260px; 
  background-color: #202020;
  padding: 20px 0; 
  position: fixed;
  top: 0;
  left: 0;
  height: 100%; 
  box-sizing: border-box;
  border-right: 0.5px solid rgba(255, 255, 255, 0.2);
}

.dashboard-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  margin-top: 110px; 
}

.dashboard-menu li {
  margin: 15px 0;
  margin-left: 15px;
  font-size: 1.2rem;
  width: 230px;
}

.dashboard-menu li a {
  display: flex;
  align-items: center; 
  padding: 10px 20px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.68px;
  color: #fcfdff;
  background-color: transparent;
  border-radius: 10px;
  transition: 0.3s ease-in-out;
}

.dashboard-menu li a svg {
  margin-right: 10px; 
}

.dashboard-menu li a.link {
  color: #bebebe;
}

.dashboard-menu li a.active {
  background-color: #3f3f3f; 
  color: #fff; 
}