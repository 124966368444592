body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100%;
    font-family: Arial, sans-serif;
}

.upload-container {
    background-color: #181717;
    height: 100vh;
    color: white;
    padding: 20px;
    font-family: Arial, sans-serif;

}

.search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-left: 30px;
}

.h1 {
    display: flex;
    margin-left: 40px;
    font-size: 1.6rem;
}

.search-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 600px;
}

.search-icon {
    position: absolute;
    left: 15px;
    margin-top: 3px;
    color: white;
    font-size: 1.4rem;
}

.search-input {
    background-color: #3f3f3f;
    border: none;
    color: white;
    border-radius: 5px;
    font-size: 1rem;
    padding: 10px 10px 10px 40px;
    width: 100%;
}

.search-input::placeholder {
    color: #fff;
}

.import-button {
    background-color: #0066ff;
    border: none;
    margin-right: 40px;
    color: white;
    width: 150px;
    font-size: 1.2rem;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
}

.file-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 30px;
    margin-left: 30px;
    margin-top: 50px;
    padding: 20px;
    max-height: 800px; 
    overflow-y: auto;  
}

.file-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    transition: transform 0.2s;
}

.file-icon {
    font-size: 80px;
    margin-bottom: 10px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #2a2a2a;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
    padding: 20px;
    margin-left: 250px;
    border-radius: 20px;
    width: 400px;
}

.close {
    float: right;
    font-size: 1.8rem;
    width: 30px; 
    height: 30px; 
    cursor: pointer;
    border-radius: 50%;
    background-color: transparent; 
    transition: background-color 0.3s ease; 
    display: flex;
    align-items: center;
    justify-content: center; 
}

.close:hover {
    background-color: lightgray; 
}


.three-dots-icon {
    display: inline-block; 
    width: 40px; 
    height: 40px; 
    border-radius: 50%;
    background-color: transparent; 
    transition: background-color 0.3s ease; 
    padding: 10px;
    box-sizing: border-box;
}


.drop-area {
    border: 2px dashed #888;
    padding: 20px;
    text-align: center;
    margin: 20px 0;
}

.browse-button,
.figma-button {
    background-color: #0066ff;
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    margin: 10px;
    cursor: pointer;
}

.max-size {
    font-size: 12px;
    color: #888;
}

.help-link {
    color: #0066ff;
    text-decoration: none;
}

.import-button:hover,
.browse-button:hover,
.figma-button:hover {
    background-color: #0056d2;
}

.import-button:focus,
.browse-button:focus,
.figma-button:focus {
    outline: 2px solid #fff;
}

.file-preview {
    width: 200px;
    height: 200px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.preview-image {
    width: 100%;
    height: 170px;
    object-fit: cover;
    border-radius: 8px;
}

.filename {
    margin-top: 8px;
    font-size: 14px;
    text-align: center;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
    display: block;
}

.model-icon {
    font-size: 64px;
    margin-bottom: 10px;
}

.file-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background: #2a2a2a;
    border-radius: 8px;
    transition: transform 0.2s;
}

.file-item:hover {
    transform: scale(1.02);
}

.model {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 180px;
    background: #3f3f3f;
    border-radius: 8px;
}

.options-menu {
    position: absolute;
    top: 10px;
    right: 0px;
}

.file-options-menu {
    position: absolute;
    bottom: 10px;
    right: 10px;
}




/* Video Preview (in grid) */
.preview-video {
    width: 100%;
    height: 170px;
    object-fit: cover;
    border-radius: 8px;
    background-color: #3f3f3f;
    filter: none; /* Ensure original color */
}

/* Video Container */
.video-container {
    position: relative;
    cursor: pointer;
}

/* Play Button Overlay for Video Preview */
.video-play-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    background-color: rgba(0, 102, 255, 0.8);
    border-radius: 50%;
    display: none;
    align-items: center;
    justify-content: center;
}

.file-preview:hover .video-play-overlay {
    display: flex;
    opacity: 1;
}

/* File name and options below the preview */
.filename {
    font-size: 14px;
    color: #fff;
    margin-top: 8px;
    text-align: center;
}

/* Video Fullscreen Styling */
.preview-video:fullscreen {
    width: 100%;
    height: 100%;
    object-fit: contain;
    pointer-events: auto; /* Allow interaction in fullscreen */
}

/* On Hover Video Effect */
.file-preview:hover .preview-video {
    transform: scale(1.02);
    transition: transform 0.2s;
}
